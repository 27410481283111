import React from "react";
import * as THREE from "three";
import "./App.css";

import Fishcam from "./Fishcam.js";
import TipsTricks from "./TipsTricks.js";
import VideoEditor from "./VideoEditor.js";
import PubSub from "pubsub-js";
import Mousetrap from "mousetrap";
import NotesSummary from "./NotesSummary.js";
import "./mousetrap-bind-dictionary.js";

import _ from "lodash";
import { timestringFromTimestamp, getToggler } from "./utils.js";

import Dialog from "@mui/material/Dialog"; // raw editor only

// todo: VideoEditor: validate input / pairs
// handle negative durations due to bad trim segments
// figure out why ~ doesn't exapnd in quotes
// split by O and D line
// todo: textarea: resize as you type
// todo: textarea: corner drag thing
// todo: factor out binding
// move raw editor out
// add video length indicator
// TODO: use https://github.com/buzz/mediainfo.js
// - to detect framerate
// - height / width ratio
// - sphere or not
// TODO: analytics?

PubSub.originalSubscribe = PubSub.subscribe;
PubSub.subscribe = function (msg, func) {
  return PubSub.originalSubscribe(msg, function (ignore, data) {
    return func(data);
  });
};

const __DEV__ = new URL(document.URL).searchParams.get("DEV") !== null;
const SKIP_DURATION = 4;
const FRAME_DURATION = 1 / 29.958504;

let Database = {};
let app = {};
let Bookmarks = {};

class Timer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentTimestamp: null,
      filename: null,
    };
  }

  render() {
    if (this.state.filename === null) {
      return null;
    }
    let timestamp = this.state.currentTimestamp || 0;
    return (
      <div title={timestamp} className="timer timestring">
        {timestringFromTimestamp(timestamp)}
        <span className="filename">{this.state.filename}</span>
      </div>
    );
  }

  componentDidMount() {
    PubSub.subscribe(
      "timeupdate",
      function (timestamp) {
        this.setState({ currentTimestamp: timestamp });
      }.bind(this)
    );
    PubSub.subscribe(
      "setFilename",
      function (filename) {
        document.title = filename.split(".").slice(0, -1).join(".") || filename;
        this.setState({ filename: filename });
      }.bind(this)
    );
  }
}

class BookmarksRawEditor extends React.PureComponent {
  textAreaOnChange(event) {
    let parsed = null;
    try {
      let raw = event.target.value.replace(/^'|'$|,$/g, "");
      raw = raw.replace(/'/g, '"');
      console.log(raw);

      parsed = JSON.parse(raw);
      event.target.value = raw;
    } catch (ex) {
      // if not parseable - don't allow change
      return;
    }
    PubSub.publish("doSetBookmarks", parsed);
  }

  componentDidCatch(error, info) {
    console.log("Catching...");
    console.log(error);
    console.log(info);
  }

  render() {
    const jsonString = JSON.stringify(this.props.bookmarks);
    const style = {
      wordWrap: "none",
      padding: 5,
      height: 250,
      width: 200,
      marginTop: 5,
      fontFamily: "monospace",
    };
    return (
      <Dialog open={true} onClose={this.props.toggle} maxWidth="md">
        <div className="bookmarksRawEditor" style={{ margin: 10 }}>
          Beware: pasting or editing valid JSON in this textarea will
          <br />
          overwrite existing bookmarks - for better or for worse.
          <br />
          <textarea
            ref="textarea"
            onChange={this.textAreaOnChange}
            value={jsonString}
            style={style}
          />
        </div>
      </Dialog>
    );
  }
}

class Video extends React.PureComponent {
  render() {
    return (
      <video
        /* controls */
        ref="video"
        id="video"
        className="video"
        autoPlay
        muted={__DEV__}
        preload="auto"
      ></video>
    );
  }

  skip(delta) {
    this.seek(this.video.currentTime + delta);
  }
  skipForward(delta) {
    this.skip(delta);
  }
  skipBack(delta) {
    this.skip(-1.0 * delta);
  }
  skipToPrevFrame() {
    this.skipBack(FRAME_DURATION);
  }
  skipToNextFrame() {
    this.skipForward(FRAME_DURATION);
  }
  seek(timestamp) {
    timestamp = Math.max(0, Math.min(timestamp, this.video.duration));
    this.video.currentTime = timestamp;
  }
  setPlaybackRate(double) {
    this.video.playbackRate = double;
  }
  togglePause() {
    this.video.paused ? this.video.play() : this.video.pause();
  }
  toggleMute() {
    this.video.muted = !this.video.muted;
  }
  skipToNextBookmark() {
    var nextTimestamp = Bookmarks.getNextBookmarkTimestamp(
      this.video.currentTime
    );
    this.seek(nextTimestamp);
  }
  skipToPrevBookmark() {
    // include .5s buffer to make it possible to get to 2 bookmarks ago
    var prevTimestamp = Bookmarks.getPrevBookmarkTimestamp(
      Math.max(this.video.currentTime - 0.5, 0)
    );
    this.seek(prevTimestamp);
  }
  setSrc(src) {
    this.video.src = src;
  }

  publishAddBookmark(event) {
    let time = Math.round(this.video.currentTime * 1000) / 1000;
    PubSub.publish("doAddBookmark", [time, event]);
  }

  readJSONFile() {
    let input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "text/plain,application/json");
    input.addEventListener("change", function () {
      let reader = new FileReader();
      reader.onload = () => {
        PubSub.publish("doSetBookmarks", JSON.parse(reader.result));
      };
      reader.readAsText(this.files[0]);
    });
    input.click();
  }

  bindHotkeys() {
    // hold down a digit key to alter playback rate
    Mousetrap.bind(
      {
        2: _.bind(this.setPlaybackRate, this, 0.1),
        3: _.bind(this.setPlaybackRate, this, 0.33),
        4: _.bind(this.setPlaybackRate, this, 0.5),
        5: _.bind(this.setPlaybackRate, this, 0.66),

        6: _.bind(this.setPlaybackRate, this, 2.0),
        7: _.bind(this.setPlaybackRate, this, 5.0),
        8: _.bind(this.setPlaybackRate, this, 7.0),
        9: _.bind(this.setPlaybackRate, this, 10.0),
      },
      "keydown"
    );

    _.range(10).forEach(
      function (digit) {
        // hold down a digit key to alter playback rate, release to return to 1x
        Mousetrap.bind(
          String(digit),
          this.setPlaybackRate.bind(this, 1),
          "keyup"
        );
      }.bind(this)
    );

    Mousetrap.bind({
      a: _.bind(this.skipToPrevBookmark, this),

      s: _.bind(this.skipBack, this, 60),
      d: _.bind(this.skipBack, this, 15),
      f: _.bind(this.skipBack, this, SKIP_DURATION),

      j: _.bind(this.skipForward, this, SKIP_DURATION),
      k: _.bind(this.skipForward, this, 15),
      l: _.bind(this.skipForward, this, 60),

      ";": _.bind(this.skipToNextBookmark, this),

      ",": _.bind(this.skipToPrevFrame, this),
      ".": _.bind(this.skipToNextFrame, this),

      space: _.bind(function (e) {
        e.preventDefault();
        if (document.activeElement === this.video) {
          document.activeElement.blur();
        }
        this.togglePause();
      }, this),

      m: _.bind(this.toggleMute, this),

      // Mark as Receiving or Pulling, Undo, and Export.
      r: _.bind(this.publishAddBookmark, this, "receive"),
      p: _.bind(this.publishAddBookmark, this, "pull"),
      t: _.bind(this.publishAddBookmark, this, "turnover"),
      i: _.bind(this.publishAddBookmark, this, "goal"),
      "shift+t": _.bind(this.publishAddBookmark, this, "timeout"),
      g: _.bind(this.publishAddBookmark, this, "startTrim"),
      h: _.bind(this.publishAddBookmark, this, "endTrim"),
      "shift+z": _.partial(PubSub.publish, "doUndoBookmarks"),
      "mod+z": _.partial(PubSub.publish, "doUndoBookmarks"),

      "=": Database.loadNextFile,
      "-": Database.loadPreviousFile,

      "shift+o": _.bind(this.readJSONFile, this),
      "shift+j": function () {
        // TODO: make this better
        function promptTimestring() {
          let timestringNum = parseInt(
            prompt("Jump to where? Format 1:27 as 127"),
            10
          );
          if (isNaN(timestringNum)) {
            return null;
          }
          let timestamp = 0;
          timestamp += timestringNum % 100;
          timestringNum = Math.floor(timestringNum / 100);
          timestamp += 60 * (timestringNum % 100);
          timestringNum = Math.floor(timestringNum / 100);
          timestamp += 60 * 60 * timestringNum;

          return timestamp;
        }
        let timestamp = promptTimestring();
        if (timestamp) {
          this.seek(timestamp);
        }
      }.bind(this),
    });
  }

  componentDidMount() {
    this.video = this.refs.video;

    PubSub.subscribe("seek", _.bind(this.seek, this));
    PubSub.subscribe("setSrc", _.bind(this.setSrc, this));

    this.refs.video.addEventListener(
      "canplay",
      _.partial(PubSub.publish, "videoCanPlay")
    );
    this.refs.video.addEventListener(
      "canplay",
      _.once(this.bindHotkeys).bind(this)
    );

    // Subscribers: Timer.state.currentTime, Bookmark.state.currentTime
    this.refs.video.addEventListener(
      "timeupdate",
      function () {
        PubSub.publish("timeupdate", this.video.currentTime);
      }.bind(this)
    );
  }
}

class BookmarksUI extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      bookmarks: [],
      filename: null,
      currentBookmarkIndex: null,
      showNotes: false,
      showVideoEditor: false,
      showRawEditor: false,
      showNotesSummary: false,
    };
    this.toggleNotes = getToggler("showNotes").bind(this);
    this.toggleVideoEditor = getToggler("showVideoEditor").bind(this);
    this.toggleRawEditor = getToggler("showRawEditor").bind(this);
    this.toggleNotesSummary = getToggler("showNotesSummary").bind(this);
  }

  calculateCurrentBookmarkIndex(timestamp) {
    let numEarlier = 0;
    this.state.bookmarks.forEach(function (bookmark, index) {
      if (bookmark[0] <= timestamp) {
        numEarlier++;
      }
    });
    if (numEarlier === 0) {
      // haven't reached first bookmark, return null
      return null;
    }
    return numEarlier - 1;
  }

  render() {
    let videoEditor = null;
    if (this.state.showVideoEditor) {
      videoEditor = (
        <VideoEditor
          bookmarks={this.state.bookmarks}
          filename={this.state.filename}
          toggle={this.toggleVideoEditor}
        />
      );
    }
    let bookmarksRawEditor = null;
    if (this.state.showRawEditor) {
      bookmarksRawEditor = (
        <BookmarksRawEditor
          bookmarks={this.state.bookmarks}
          toggle={this.toggleRawEditor}
        />
      );
    }
    let notesSummary = null;
    if (this.state.showNotesSummary) {
      notesSummary = (
        <NotesSummary
          notes={Database.getAllNotes()}
          toggle={this.toggleNotesSummary}
        />
      );
    }
    return (
      <div className={this.state.showNotes ? "showNotes" : "hideNotes"}>
        <BookmarksPrettyUI
          bookmarks={this.state.bookmarks}
          currentBookmarkIndex={this.state.currentBookmarkIndex}
        />
        {bookmarksRawEditor}
        {videoEditor}
        {notesSummary}
      </div>
    );
  }

  componentDidMount() {
    Mousetrap.bind("shift+n", this.toggleNotes);
    Mousetrap.bind("shift+v", this.toggleVideoEditor);
    Mousetrap.bind("shift+e", this.toggleRawEditor);
    Mousetrap.bind("shift+x", this.toggleNotesSummary);

    app.sortedBookmarks = [];
    const updateBookmarks = function (action, newBookmarks) {
      // important to clone as PureComponents do shallow
      // comparisons to decide whether to re-render
      let bookmarks = this.state.bookmarks.slice();
      if (action === "add") {
        bookmarks.push(newBookmarks);
      } else if (action === "set" || action === "load") {
        bookmarks = newBookmarks.slice(); // unnecessary?
      } else if (action === "undo") {
        bookmarks.pop();
      } else if (action === "remove") {
        for (let ii = 0; ii < bookmarks.length; ii++) {
          if (
            bookmarks[ii][0] === newBookmarks[0] &&
            bookmarks[ii][1] === newBookmarks[1]
          ) {
            bookmarks.splice(ii, 1);
            break;
          }
        }
      } else if (action === "updateNote") {
        for (let ii = 0; ii < bookmarks.length; ii++) {
          if (
            bookmarks[ii][0] === newBookmarks[0] &&
            bookmarks[ii][1] === newBookmarks[1]
          ) {
            bookmarks[ii][2] = newBookmarks[2];
            break;
          }
        }
      }
      this.setState({ bookmarks });
      if (action !== "load") {
        Database.saveBookmarks(bookmarks);
      }
      app.sortedBookmarks = _.sortBy(bookmarks, 0); // TODO: remove
    }.bind(this);
    PubSub.subscribe("doAddBookmark", _.bind(updateBookmarks, this, "add"));
    PubSub.subscribe("doSetBookmarks", _.bind(updateBookmarks, this, "set")); // set and save
    PubSub.subscribe("doLoadBookmarks", _.bind(updateBookmarks, this, "load")); // load without save
    PubSub.subscribe("doUndoBookmarks", _.bind(updateBookmarks, this, "undo"));
    PubSub.subscribe(
      "doRemoveBookmark",
      _.bind(updateBookmarks, this, "remove")
    );
    PubSub.subscribe(
      "doUpdateNote",
      _.bind(updateBookmarks, this, "updateNote")
    );

    PubSub.subscribe(
      "setFilename",
      function (filename) {
        this.setState({ filename: filename });
      }.bind(this)
    );

    PubSub.subscribe(
      "timeupdate",
      function (timestamp) {
        this.setState({
          currentBookmarkIndex: this.calculateCurrentBookmarkIndex(timestamp),
        });
      }.bind(this)
    );
  }
}

class SinglePrettyBookmark extends React.PureComponent {
  renderTimestamp(timestamp) {
    let onclick = function () {
      PubSub.publish("seek", timestamp);
      return false;
    };
    return (
      <span className="timestring" title={timestamp} onClick={onclick}>
        {timestringFromTimestamp(timestamp)}
      </span>
    );
  }
  renderEvent(event, eventAddendum) {
    let string = event;
    if (eventAddendum) {
      string = event + ` (${eventAddendum})`;
    }
    return <span className={`event ${event} ${eventAddendum}`}>{string}</span>;
  }

  renderScore(score, puller) {
    return (
      <span className="score">
        {score[0]} - {score[1]}
      </span>
    );
  }
  // props: score, possessor, timestamp, event, note

  render() {
    let { timestamp, event, note, score, possession, puller, isCurrent } =
      this.props;
    let classes = isCurrent ? "current" : "";
    let eventAddendum = "";
    if (event === "turnover") {
      eventAddendum = possession === 1 ? "us" : "them";
    } else if (event === "goal" && puller === possession) {
      eventAddendum = possession === 1 ? "broken" : "broke";
    }
    let removeBookmark = function () {
      PubSub.publish("doRemoveBookmark", [timestamp, event]);
    };
    let updateNote = function (changeEvent) {
      let newNote = changeEvent.target.value;
      PubSub.publish("doUpdateNote", [timestamp, event, newNote]);
    };
    return (
      <div key={timestamp} className={classes}>
        <span className="deleteX" onClick={removeBookmark}>
          x
        </span>
        {this.renderTimestamp(timestamp)}
        {this.renderEvent(event, eventAddendum)}
        {event === "goal" ? this.renderScore(score) : null}
        <div className="note">
          <BookmarkNoteTextarea value={note} updateCallback={updateNote} />
        </div>
      </div>
    );
  }
}

class BookmarkNoteTextarea extends React.PureComponent {
  constructor(props) {
    super(props);
    this.count = 0;
    this.textarea = React.createRef();
    this.resizeTextarea = this.resizeTextarea.bind(this);
  }
  static get width() {
    return 19;
  }
  guessNumRows(text) {
    if (!text || !text.length) {
      return 1;
    }
    if (text.indexOf("\n") !== -1) {
      return text
        .split("\n")
        .reduce((acc, line) => acc + this.guessNumRows(line), 0);
    }
    let width = BookmarkNoteTextarea.width;

    let lens = text.split(" ").map((x) => x.length);
    let numRows = 0;
    let curRow = 0;
    lens.forEach((len) => {
      if (len >= width) {
        curRow = len % width;
        numRows += Math.floor(len / width);
      } else if (curRow + len >= width) {
        numRows += 1;
        curRow = len;
      } else {
        curRow += len;
      }
      curRow += 1;
      if (curRow > width) {
        numRows += 1;
        curRow = 0;
      }
    });
    return numRows + 1;
  }
  resizeTextarea() {
    let node = this.textarea.current;
    node.rows = this.guessNumRows(node.value);
  }
  render() {
    return (
      <textarea
        className="noteTextarea"
        ref={this.textarea}
        style={{ width: "94%", marginLeft: "2px" }}
        cols={BookmarkNoteTextarea.width}
        onChange={this.props.updateCallback}
        onInput={this.resizeTextarea}
        onKeyUp={(e) => {
          // https://bugs.chromium.org/p/chromium/issues/detail?id=9061#c15
          if (e.key === "Escape") {
            e.preventDefault();
            e.target.blur();
          }
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter" && !e.getModifierState("Shift")) {
            e.preventDefault();
            e.target.blur();
          }
        }}
        defaultValue={this.props.value}
      />
    );
  }
  componentDidMount() {
    this.resizeTextarea();
  }
}

Bookmarks.getTimestampNeighbors = function (bookmarks, timestamp) {
  const timestamps = [-Infinity, ...bookmarks.map(_.head), Infinity];
  const index = _.sortedIndex(timestamps, timestamp);
  let prev, next;
  if (timestamps[index] === timestamp) {
    prev = index - 1;
    next = index + 1;
  } else {
    prev = index - 1;
    next = index;
  }
  return {
    prev: Math.max(0.0, timestamps[prev]),
    next: Math.min(Infinity, timestamps[next]),
  };
};

Bookmarks.getNextBookmarkTimestamp = function (timestamp) {
  return Bookmarks.getTimestampNeighbors(app.sortedBookmarks, timestamp).next;
};
Bookmarks.getPrevBookmarkTimestamp = function (timestamp) {
  return Bookmarks.getTimestampNeighbors(app.sortedBookmarks, timestamp).prev;
};

class BookmarksPrettyUI extends React.PureComponent {
  render() {
    const { bookmarks, currentBookmarkIndex } = this.props;
    let score = [0, 0];
    let possession = null;
    let puller = null;
    return (
      <div className="bookmarks">
        {_.sortBy(bookmarks, 0).map(function (bookmark, index) {
          let [timestamp, event, note] = bookmark;
          if (event === "pull") {
            possession = 1;
            puller = 0;
          } else if (event === "receive") {
            possession = 0;
            puller = 1;
          } else if (event === "turnover") {
            possession = 1 - possession;
          } else if (event === "goal") {
            ++score[possession];
          }
          return (
            <SinglePrettyBookmark
              {...{ timestamp, event, note, possession, puller }}
              isCurrent={index === currentBookmarkIndex}
              key={timestamp}
              score={score.slice()}
            />
          );
        })}
      </div>
    );
  }
}

class FileSelector extends React.PureComponent {
  render() {
    return (
      <input
        type="file"
        multiple={true}
        className="mousetrap fileSelector"
        accept="video/*,.mkv"
        style={{ width: 98 }}
        onChange={this.onChange}
      />
    );
  }
  onChange(event) {
    try {
      Database.receiveFiles(event.target.files);
    } catch (ex) {
      alert(ex.message);
    }
  }
  componentDidMount() {
    Mousetrap.bind("o", function () {
      document.querySelector("input.fileSelector").click();
    });
  }
}

class UploadPrompt extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { show: true };
  }
  render() {
    return (
      <div
        className={"uploadPromptWrapper" + (this.state.show ? "" : " hidden")}
      >
        <div className="uploadPromptItem">Select videos to watch</div>
        <div className="uploadPromptItem">Or drag and drop video files</div>
        <div className="uploadPromptItem">
          <FileSelector />
        </div>
      </div>
    );
  }
  componentDidMount() {
    PubSub.subscribe(
      "setSrc",
      function () {
        this.setState({ show: false });
      }.bind(this)
    );
  }
}

class Player extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showTips: false,
      showSphere: false,
    };
    this.toggleTips = getToggler("showTips").bind(this);
    this.toggleSphere = getToggler("showSphere").bind(this);
  }
  render() {
    return (
      <div className="player">
        <div className="leftColumnWrapper">
          <TipsTricks
            show={this.state.showTips}
            shortcuts={Player.keyboardShortcuts}
            toggle={this.toggleTips}
          />
          <BookmarksUI />
        </div>
        <div className="videoWrapper">
          <UploadPrompt />
          <Video />
          <div id="sphereWrapper" />
          <Timer className="timer" />
        </div>
      </div>
    );
  }
  componentDidMount() {
    Mousetrap.bind("?", this.toggleTips);
    Mousetrap.bind("shift+s", () => {
      let sphereWrapper = document.getElementById("sphereWrapper");
      let video = document.getElementById("video");
      // TODO: NEED TO TEAR DOWN when switching to different video
      // https://threejs.org/docs/index.html#manual/en/introduction/How-to-dispose-of-objects
      if (this.state.showSphere) {
        video.style.display = "block";
        sphereWrapper.replaceChildren();
      } else {
        let renderer = createSphere(video);
        if (!renderer) return;
        video.style.display = "none";
        sphereWrapper.replaceChildren(renderer.domElement);
      }
      this.toggleSphere();
    });
    PubSub.subscribe("setSrc", () => {
      if (this.state.showSphere) {
        Mousetrap.trigger("shift+3");
      }
    });

    let equalizePanelHeights = () => {
      let player = document.querySelector(".player");
      player.children[0].style.height = player.children[1].offsetHeight + "px";
    };

    window.addEventListener("resize", equalizePanelHeights);
    equalizePanelHeights();
  }
}

// credit: https://gist.github.com/bepro-dev/a640f0c2048d4d02a063476ee8a2a3a3
// https://superuser.com/questions/1795588/how-do-unwrap-fisheye-and-overlap-fisheye-lens-with-ffmpeg
// https://github.com/mrdoob/three.js/blob/master/examples/webgl_video_panorama_equirectangular.html
// https://github.com/mrdoob/three.js/blob/master/examples/webgl_materials_video.html
const X3_360_FOV = 193;
function createSphere(videoElement) {
  // TODO: need to undo these changes when loading new video!
  if (!videoElement || !videoElement.src) {
    return;
  }

  let file = Database.fileList[Database.fileIndex];
  let isInsvFile = file.name.endsWith(".insv");
  let fovDegrees = isInsvFile ? X3_360_FOV : 360;
  let phiWidth = THREE.MathUtils.degToRad(fovDegrees);
  let phiStart = isInsvFile ? THREE.MathUtils.degToRad(90) : 0;

  let { width, height } = videoElement.parentNode.getBoundingClientRect();
  const vHeight = videoElement.videoHeight;
  const vWidth = videoElement.videoWidth;
  let camera, scene, renderer;

  let isUserInteracting = false,
    lon = 0,
    lat = 0,
    phi = 0,
    theta = 0,
    onPointerDownPointerX = 0,
    onPointerDownPointerY = 0,
    onPointerDownLon = 0,
    onPointerDownLat = 0;

  let recalc = true;

  let distance = 0.5;

  videoElement.play();
  init();
  animate();

  function init() {
    camera = new THREE.PerspectiveCamera(75, vWidth / vHeight, 0.25, 10);
    scene = new THREE.Scene();

    const geometry = new THREE.SphereGeometry(5, 60, 40, phiStart, phiWidth);
    // invert the geometry on the x-axis so that all of the faces point inward
    geometry.scale(-1, 1, 1);
    const video = videoElement;
    const texture = new THREE.VideoTexture(video);
    texture.colorSpace = THREE.SRGBColorSpace;

    if (isInsvFile && 2 * vHeight === vWidth) {
      console.log("Using only right half");
      texture.repeat.x = 0.5; // Use only half of the image width
      texture.offset.x = 0.5;
    }
    const material = new THREE.MeshBasicMaterial({ map: texture });
    const mesh = new THREE.Mesh(geometry, material);
    scene.add(mesh);

    renderer = new THREE.WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);

    renderer.domElement.addEventListener("pointerdown", onPointerDown);
    renderer.domElement.addEventListener("pointermove", onPointerMove);
    renderer.domElement.addEventListener("pointerup", onPointerUp);
    renderer.domElement.addEventListener("wheel", (e) => zoom(e.deltaY));
    window.addEventListener("resize", onWindowResize);
  }
  function onWindowResize() {
    let { width, height } = videoElement.parentNode.getBoundingClientRect();
    console.log(width, height);
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    renderer.setSize(width, height);
  }
  function zoom(delta) {
    camera.fov = THREE.MathUtils.clamp(camera.fov + delta / 10, 5, 135);
    camera.updateProjectionMatrix();
  }
  function onPointerDown(event) {
    isUserInteracting = true;
    onPointerDownPointerX = event.clientX;
    onPointerDownPointerY = event.clientY;
    onPointerDownLon = lon;
    onPointerDownLat = lat;
  }
  function onPointerMove(event) {
    if (isUserInteracting === true) {
      if (!event.shiftKey) {
        recalc = true;
        lon = (onPointerDownPointerX - event.clientX) * 0.1 + onPointerDownLon;
      }
      if (event.shiftKey || event.metaKey) {
        recalc = true;
        lat = (onPointerDownPointerY - event.clientY) * 0.1 + onPointerDownLat;
      }
    }
  }
  function panX(delta) {
    lon += delta * 0.1;
  }
  function panY(delta) {
    lat += delta * 0.1;
  }
  const panUnit = 40;
  Mousetrap.bind("up", () => panY(panUnit));
  Mousetrap.bind("down", () => panY(-1 * panUnit));
  Mousetrap.bind("left", () => panX(-1 * panUnit));
  Mousetrap.bind("right", () => panX(panUnit));
  Mousetrap.bind("[", () => zoom(50));
  Mousetrap.bind("]", () => zoom(-50));

  function onPointerUp() {
    isUserInteracting = false;
  }
  function animate() {
    requestAnimationFrame(animate);
    update();
  }
  function update() {
    if (recalc) {
      recalc = false;
      lat = Math.max(-85, Math.min(85, lat));
      phi = THREE.MathUtils.degToRad(90 - lat);
      theta = THREE.MathUtils.degToRad(lon);

      camera.position.x = distance * Math.sin(phi) * Math.cos(theta);
      camera.position.y = distance * Math.cos(phi);
      camera.position.z = distance * Math.sin(phi) * Math.sin(theta);

      camera.lookAt(0, 0, 0);
    }

    renderer.render(scene, camera);
  }

  return renderer;
}

Player.keyboardShortcuts = [
  ["spacebar", "play / pause"],
  [["s", "d", "f"], `skip back: 60s, 15s, ${SKIP_DURATION}s`],
  [["j", "k", "l"], `skip forward: ${SKIP_DURATION}s, 15s, 60s`],
  [[",", "."], "skip back / forward by 1 frame"],
  [["a", ";"], "jump to previous/next bookmark"],
  ["m", "mute / unmute"],
  [["2", "3", "4", "5"], `hold to slow down playback`],
  [["6", "7", "8", "9"], `hold to speed up playback`],
  ["o", "open video"],
  [["p", "r", "t", "i", "g", "h"], `add bookmarks`],
  [["\u229e Win / \u2318 Mac", "z"], "undo last added bookmark"],
  [["shift", "e"], "raw bookmarks editor"],
  [["shift", "n"], "show notes"],
  [["shift", "v"], "video editor"],
  [["shift", "c"], "display webcam"],
  [["-", "="], "load previous / next file"],
];

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showCam: false };
  }
  render() {
    return (
      <div
        className="dropzone"
        onDrop={this.onDrop}
        onDragOver={this.onDragOver}
      >
        <Player />
        {this.state.showCam ? <Fishcam /> : null}
      </div>
    );
  }
  componentDidMount() {
    if (__DEV__) {
      // preload video
      // TODO: need to add these files to Database
      const preloadVideo = "mini180.insv";
      PubSub.publish("setFileID", 125829120);
      PubSub.publish("setFilename", preloadVideo);
      PubSub.publish("setSrc", preloadVideo);
      setTimeout(() => {
        Mousetrap.trigger("shift+3");
      }, 2500);
    }

    Mousetrap.bind("shift+c", () =>
      this.setState({ showCam: !this.state.showCam })
    );
  }

  onDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    try {
      Database.receiveFiles(Array.from(e.dataTransfer.files));
    } catch (ex) {
      alert(ex.message);
    }
  }

  onDragOver(e) {
    let items = e.dataTransfer.items;
    if (items.length === 0 || items[0].kind !== "file") {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    e.dataTransfer.dropEffect = "copy";
  }
}

Database = {
  fileID: null,
  filename: null,
  fileList: [],
  fileIndex: null,

  getAllNotes: function () {
    let allNotes = Database.fileList.map((file) => {
      let bookmarks = Database.getBookmarks(file.size) || [];
      bookmarks = bookmarks.filter((bookmark) => bookmark[2]);
      return {
        filename: file.name,
        notes: bookmarks.map((bookmark) => bookmark[2]),
      };
    });
    return allNotes;
  },

  getCookieName: function (fileID) {
    if (fileID === undefined) {
      fileID = Database.fileID;
    }
    return `bm-${fileID}`;
  },

  saveBookmarks: function (contents) {
    let version = 2;
    let struct = {
      filename: Database.filename,
      version: version,
      bookmarks: contents,
    };
    localStorage.setItem(Database.getCookieName(), JSON.stringify(struct));
  },

  debugStorage: function () {
    // for customer support
    let arr = [JSON.stringify(localStorage)];
    console.log(arr);
    alert(arr);
  },

  getBookmarks: function (fileID) {
    let cookie = localStorage.getItem(Database.getCookieName(fileID));
    if (!cookie) {
      return null;
    }
    let contents = JSON.parse(cookie);
    let version = contents.version;

    if (version === 2) {
      return contents["bookmarks"];
    }
    alert("Invalid bookmark contents in cookie");
  },

  receiveFiles(fileList) {
    let files = Array.from(fileList);
    if (files.length === 0) {
      throw new Error(`No files selected.`);
    }
    let badFiles = files.filter((file) => {
      return (
        file.type.split("/")[0] !== "video" &&
        !file.name.endsWith(".mkv") &&
        !file.name.endsWith(".insv")
      );
    });
    if (badFiles.length > 0) {
      throw new Error(
        "You selected files that aren't videos:\n" +
          badFiles.map((file) => `• ${file.name} (${file.type})`).join("\n")
      );
    }
    Database.fileList.push(...fileList);
    Database.loadFileAtIndex(Database.fileList.length - fileList.length);
  },
  loadFileAtIndex(index) {
    let file = Database.fileList[index];
    Database.fileIndex = index;
    PubSub.publish("setSrc", URL.createObjectURL(file));
    PubSub.publish("setFilename", file.name);
    PubSub.publish("setFileID", file.size);
  },
  loadPreviousFile() {
    if (Database.fileIndex - 1 >= 0) {
      Database.loadFileAtIndex(--Database.fileIndex);
    }
  },
  loadNextFile() {
    if (Database.fileIndex + 1 < Database.fileList.length) {
      Database.loadFileAtIndex(++Database.fileIndex);
    }
  },
};
PubSub.subscribe("setFilename", function (filename) {
  Database.filename = filename;
});
PubSub.subscribe("setFileID", function (fileID) {
  Database.fileID = fileID;
  let bookmarks = Database.getBookmarks();
  if (!bookmarks) {
    bookmarks = [];
  }
  PubSub.publish("doLoadBookmarks", bookmarks);
});

window.fisheyeDatabase = Database; // for debugging

export default App;
