import React from "react";
import Dialog from "@mui/material/Dialog";
import Chopper from "./Chopper.js";
import { textTransform } from "@mui/system";

class VideoEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        inDir: ".",
        outDir: ".",
        preStart: 1,
        postEnd: 2,
        muteVideo: false,
        doTrim: true,
        notifications: navigator.platform.toUpperCase().indexOf("MAC") >= 0,
        startEvents: ["pull", "receive"],
        endEvents: ["goal"],
        line: "either",
        possession: "either",
      },
    };
    this.handleChange = this.handleChange.bind(this);
  }
  getAllEvents() {
    return [
      "pull",
      "receive",
      "turnover (us)",
      "turnover (them)",
      "goal",
      "startTrim",
      "endTrim",
    ];
  }
  getAllLines() {
    return ["pull", "receive", "either"];
  }
  prettifyLogs(logs) {
    // prepare logs for output
    let lines = [];
    logs.forEach((log) => {
      if (Array.isArray(log)) {
        log = log.map((x) => x.join(", ")).join("\n");
      }
      lines.push(log);
    });
    return lines.join("\n\n");
  }

  getCommandsAndLogs() {
    // massage stored state into inputs to Chopper.js
    let myArgs = { ...this.state.form };
    if (!myArgs.outDir) {
      myArgs.outDir = ".";
    }
    if (!myArgs.inDir) {
      myArgs.inDir = ".";
    }
    try {
      if (!this.props.filename) {
        throw new Error("Need to load a file first");
      }
      myArgs.inFile = myArgs.inDir + "/" + this.props.filename;
      myArgs.preStart = parseFloat(myArgs.preStart);
      myArgs.postEnd = parseFloat(myArgs.postEnd);
      ["preStart", "postEnd"].forEach((name) => {
        if (isNaN(myArgs[name]) || myArgs[name] < 0) {
          throw new Error(`${name} must be a non-negative number.`);
        }
      });
      ["inDir", "outDir"].forEach((name) => {
        if (myArgs[name].startsWith("~")) {
          throw new Error(
            `${name}: root dir (~) should be written out (ex: /Users/bfunk)`
          );
        }
        if (myArgs[name].includes("/")) {
          throw new Error(
            `${name}: can only be one level deep from current directory`
          );
        }
      });
    } catch (e) {
      return [[], [], [e.message]];
    }
    myArgs.eventFilter = [
      this.state.form.startEvents,
      this.state.form.endEvents,
    ];

    let [commands, logs, errors] = Chopper.getCommandsEverything(
      // throws
      this.props.bookmarks,
      myArgs
    );
    return [commands, logs, errors];
  }

  handleChange(evt) {
    let formData = new FormData(evt.target.form);
    let form = {};
    ["inDir", "outDir", "preStart", "postEnd", "line", "possession"].forEach(
      (name) => (form[name] = formData.get(name))
    );
    ["muteVideo", "doTrim", "notifications"].forEach(
      (name) => (form[name] = !!formData.get(name))
    );
    ["startEvents", "endEvents"].forEach(
      (name) => (form[name] = formData.getAll(name))
    );
    if (form.possession !== "either" && form.line !== "either") {
      let line = form.line[0].toUpperCase();
      let poss = form.possession === "us" ? "offense" : "defense";
      form.outDir = `${line}-line-${poss}`;
    }
    this.setState({ form });
    // because this.sate.form is a complex data structure and PureComponent only
    // does shallow comparison
    this.forceUpdate();
  }

  render() {
    let [commands, logs, errors] = this.getCommandsAndLogs();
    let errorsDiv = null;
    if (errors) {
      errorsDiv = (
        <div
          style={{ border: "1px red solid", background: "red", padding: 10 }}
        >
          ERROR:
          <br />
          {errors.join("<br />")}
        </div>
      );
    }
    let bookmarksStyle =
      this.state.form.possession === "either" ? {} : { display: "none" };
    return (
      <div>
        <Dialog open={true} onClose={this.props.toggle} maxWidth="lg">
          <div style={{ padding: "10px" }}>
            <div>Get To Da Choppa</div>
            <br />
            <div>
              Instructions: this widget helps produce ffmpeg commands you can
              copy and paste into your terminal. <br />
              You will need to have&nbsp;
              <a href="https://www.ffmpeg.org/download.html">ffmpeg</a>, a
              command line video editor, installed.
            </div>
            <br />
            <form>
              Input video file name:{" "}
              <span style={{ fontFamily: "monospace" }}>
                {this.props.filename}
              </span>
              <br />
              Path to input video file:
              <input
                type="text"
                name="inDir"
                value={this.state.form["inDir"]}
                onChange={this.handleChange}
              />
              &nbsp; (by default uses directory the command is run from)
              <br />
              Output directory:
              <input
                type="text"
                name="outDir"
                value={this.state.form["outDir"]}
                onChange={this.handleChange}
              />
              &nbsp; (by default uses directory the command is run from)
              <br />
              Strip Audio (eg. YouTube dislikes copyrighted background music):
              <input
                type="checkbox"
                name="muteVideo"
                checked={this.state.form["muteVideo"]}
                onChange={this.handleChange}
              />
              <br />
              Trim out segments demarked by [startTrim, endTrim]:
              <input
                type="checkbox"
                name="doTrim"
                checked={this.state.form["doTrim"]}
                onChange={this.handleChange}
              />
              <br />
              Pre-Start:
              <input
                type="text"
                size="5"
                name="preStart"
                value={this.state.form["preStart"]}
                onChange={this.handleChange}
              />{" "}
              seconds
              <br />
              Post-End:
              <input
                type="text"
                size="5"
                name="postEnd"
                value={this.state.form["postEnd"]}
                onChange={this.handleChange}
              />{" "}
              seconds
              <br />
              Include Mac OS notifications (Mac only):
              <input
                type="checkbox"
                name="notifications"
                checked={this.state.form["notifications"]}
                onChange={this.handleChange}
              />
              <br />
              <br />
              <table>
                <tbody>
                  <tr>
                    <td width="300px">
                      Of points beginning with:
                      <br />
                      {this.getAllLines().map((longName) => {
                        let name = longName.split(" ")[0];
                        return (
                          <div key={longName}>
                            <input
                              type="radio"
                              name="line"
                              id={name}
                              value={longName}
                              onChange={this.handleChange}
                              checked={this.state.form.line === longName}
                            ></input>
                            <label htmlFor={name}>{name}</label>
                          </div>
                        );
                      })}
                      <br />
                      (in beta) when possession is with:
                      <br />
                      {["us", "them", "either"].map((team) => {
                        return (
                          <div key={team}>
                            <input
                              type="radio"
                              name="possession"
                              id={team}
                              value={team}
                              onChange={this.handleChange}
                              checked={this.state.form.possession === team}
                            ></input>
                            <label htmlFor={team}>{team}</label>
                          </div>
                        );
                      })}
                    </td>
                    <td width="220px" style={bookmarksStyle}>
                      Start Events:
                      <br />
                      {this.getAllEvents().map((name) => {
                        return (
                          <div key={name}>
                            <label>
                              <input
                                type="checkbox"
                                name={"startEvents"}
                                value={name}
                                checked={this.state.form[
                                  "startEvents"
                                ].includes(name)}
                                onChange={this.handleChange}
                              ></input>
                              {name}
                            </label>
                            <br />
                          </div>
                        );
                      })}
                    </td>
                    <td width="220px" style={bookmarksStyle}>
                      End Events: (optional)
                      <br />
                      {this.getAllEvents().map((name) => {
                        return (
                          <div key={name}>
                            <label>
                              <input
                                type="checkbox"
                                name={"endEvents"}
                                value={name}
                                checked={this.state.form["endEvents"].includes(
                                  name
                                )}
                                onChange={this.handleChange}
                              ></input>
                              {name}
                            </label>
                            <br />
                          </div>
                        );
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
            <br />
            {errorsDiv}
            Command:
            <br />
            <textarea
              name="commands"
              style={{ fontFamily: "monospace" }}
              rows={20}
              cols={120}
              value={commands.join("; \\\n")}
              readOnly
            />
            <br />
            <br />
            Logs:
            <br />
            <textarea
              name="logs"
              style={{ fontFamily: "monospace" }}
              rows={20}
              cols={120}
              value={this.prettifyLogs(logs)}
              readOnly
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

export default VideoEditor;
