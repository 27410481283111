import React from "react";
import Dialog from "@mui/material/Dialog";
import "./TipsTricks.css";

class TipsTricks extends React.PureComponent {
  render() {
    return (
      <div>
        <span ref="link" className="tipsTricks" onClick={this.props.toggle}>
          Keyboard shortcuts
        </span>
        {this.props.show ? this.renderDialog() : null}
      </div>
    );
  }
  renderDialog() {
    const commitDate = process.env.REACT_APP_GIT_DATE.split(" ");
    return (
      <Dialog open={true} onClose={this.props.toggle} maxWidth="sm">
        <div id="hotkeyDialog" style={{ padding: 10 }}>
          {this.props.shortcuts.map(function (args, index) {
            var keys = args[0];
            keys = Array.isArray(keys) ? keys : [keys];
            var description = args[1];
            return (
              <div className="hotkeyRow" key={index}>
                <div className="leftCol">
                  {keys.map(function (key, index, arr) {
                    return <kbd key={index}>{key}</kbd>;
                  })}
                </div>
                <div className="rightCol">
                  <span>{description}</span>
                </div>
              </div>
            );
          })}
          <div className="version">
            Last updated:{" "}
            <span title={process.env.REACT_APP_GIT_SHA}>
              {commitDate[1]} {commitDate[2]}, {commitDate[4]}
            </span>
            <br />
            <a
              href="mailto:wpchen+fisheye@gmail.com?subject=Fisheye+Feedback"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact me
            </a>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default TipsTricks;
