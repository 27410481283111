function getToggler(key) {
  return function () {
    return this.setState({ [key]: !this.state[key] });
  };
}

function getFileID(file) {
  return file.size;
}

// Converts a timestring to a timestamp (ex: "0:01:14" => 74)
function timestampFromTimestring(timestring) {
  var matches = timestring.match(/^(\d+):(\d+):(\d+)$/);
  if (!matches) {
    throw new Error(
      "Attempting to convert invalid timestring to timestamp: " + timestring
    );
  }
  var hour = parseInt(matches[1], 10);
  var min = parseInt(matches[2], 10);
  var sec = parseInt(matches[3], 10);
  return 3600 * hour + 60 * min + sec;
}

// ex: 5437.189333 => "01:30:37.189"
function timestringFromTimestamp(timestamp) {
  timestamp = Math.round(timestamp * 1000);
  var millisec = timestamp % 1000;
  timestamp = Math.floor(timestamp / 1000);
  var sec = timestamp % 60;
  timestamp = Math.floor(timestamp / 60);
  var min = timestamp % 60;
  var hour = Math.floor(timestamp / 60);

  if (hour === 0) {
    hour = "0";
  }

  if (min === 0) {
    min = "00";
  } else if (min < 10) {
    min = "0" + min;
  }

  if (sec < 10) {
    sec = "0" + sec;
  }
  millisec = Math.floor(millisec / 100);

  return hour + ":" + min + ":" + sec + "." + millisec;
}

export {
  timestringFromTimestamp,
  getFileID,
  timestampFromTimestring,
  getToggler,
};
