import React from "react";
import Dialog from "@mui/material/Dialog";

// TODO: hacky / beta
class NotesSummary extends React.PureComponent {
  render() {
    let notes = this.props.notes || [];
    let commands = [];
    notes.forEach((file) => {
      if (file.notes.length === 0) {
        return;
      }
      let filename = file.filename;
      let text = file.notes.join(", ");
      text = text.replace("\n", "");
      text = text.trim(" ");
      let parts = filename.split(".");
      if (parts.length === 1) {
        throw new Error(`Weird file with no suffix: ${filename}`);
      }
      parts[parts.length - 2] += ` + ${text}`;
      let newName = parts.join(".");
      if (newName.length >= 255) {
        throw new Error("Filename too long");
      }
      if (filename.indexOf("'") !== -1) {
        throw new Error(`Original filename includes single quote: ${filename}`);
      }
      if (newName.indexOf("'") !== -1) {
        throw new Error(`Note includes single quote: ${text}`);
      }
      commands.push(`mv '${file.filename}' '${newName}';`);
    });
    return (
      <Dialog
        open={true}
        style={{ margin: 20 }}
        onClose={this.props.toggle}
        maxWidth="lg"
      >
        <div style={{ padding: 10 }}>
          All notes:
          <table style={{ width: 300 }}>
            <tbody>
              {notes.map((file, index) => {
                if (file.notes.length === 0) {
                  return null;
                }
                return (
                  <tr key={index}>
                    <td>{file.filename}</td>
                    <td>{file.notes.join(", ")}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <textarea
            readOnly
            cols={120}
            rows={10}
            value={commands.join("\n")}
          ></textarea>
        </div>
      </Dialog>
    );
  }
}

export default NotesSummary;
