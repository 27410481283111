import { createRoot } from "react-dom/client";
import App from "./App";

const isChrome = navigator.userAgent.toLowerCase().indexOf("chrome") > -1;
const isDev = window.location.href.indexOf("DEV") > -1;
if (!isChrome && !isDev) {
  alert("Warning: this site has only been tested on Chrome (for desktop).");
}

const container = document.getElementById("app");
const root = createRoot(container);
root.render(<App />);
