// check for end of file
// all turnovers
// all possessions
// all possessions resulting in turns
// all posssessions resulting in goals
// all turnovers

// get rid of short clips
// merge clips broken up by trims

function getCommandsEverything(bookmarks, args) {
  let logBackup = console.log;
  let logs = [];
  console.log = function () {
    logs.push.apply(logs, arguments);
    //logBackup.apply(console, arguments);
  };

  let {
    eventFilter,
    preStart,
    postEnd,
    inFile,
    outDir,
    notifications,
    doTrim,
    line,
    possession,
    muteVideo,
  } = args;
  let allSegments = null;
  let commands = null;
  let jobLength;
  try {
    allSegments = getSegmentsFromBookmarks(
      bookmarks,
      [eventFilter, [preStart, postEnd], line, possession],
      doTrim
    );
    jobLength = allSegments.reduce((acc, cur) => acc + cur[1] - cur[0], 0);

    // segments -> commands
    commands = getChopCommands(allSegments, inFile, outDir, muteVideo);
    if (outDir !== ".") {
      commands.unshift(`mkdir ${outDir}`);
      commands.push(
        `mv ${outDir} ${outDir}\\ \\(${Math.round(jobLength / 60)}-mins\\)`
      );
    }
  } catch (e) {
    return [[], logs, [e.message]];
  } finally {
    console.log = logBackup;
  }

  if (notifications && commands.length) {
    // Add in OS X notifications
    let jobLengthTime =
      jobLength < 60
        ? `${phpRound(jobLength, 0)} sec`
        : `${phpRound(jobLength / 60.0, 1)} min`;
    let notificationBase =
      `osascript -e 'display notification ` +
      `"${allSegments.length} file (${jobLengthTime}) job at ` +
      `" & (time string of (current date)) with title "ffmpeg"`;
    commands.unshift(notificationBase + ` subtitle "Started"'`);
    commands.push(notificationBase + ` subtitle "Finished" sound name "Ping"'`);
  }
  return [commands, logs, null];
}

function getSegmentsFromBookmarks(bookmarks, filterArr, doTrim) {
  let [eventFilter, [preStart, postEnd], line, possession] = filterArr;
  // TODO: throw error is user wants to trim out startTrim/endTrim
  // but also selected startTrim/endTrim as start and end events
  // bookmarks -> sorted bookmarks
  bookmarks.sort((a, b) => a[0] - b[0]);
  console.log(`${bookmarks.length} bookmarks.`);

  // sorted bookmarks -> bookmarks w/ only line interested in
  bookmarks = getBookmarksWithContext(bookmarks);
  bookmarks = bookmarks.filter((bookmark) => {
    return line === "either" || line === bookmark[2];
  });
  console.log(bookmarks);

  // sorted bookmarks -> events
  // events is array of two-ples: [event, time],
  // where event is "turnover" or ["pull", "goal"]
  // and time is "123.4" or [123.4, 126.5]
  let events = getEvents(bookmarks, eventFilter, possession);
  console.log(
    `${events.length} events matching ${JSON.stringify(eventFilter)}:`
  );
  console.log(events);

  // events -> segments
  //let segments = getTurnoverSegmentsFromPoints(points)
  //let segments = getOffenseSegmentsFromPoints(events);
  let segments = getSegments(events, preStart, postEnd);
  console.log(
    `${segments.length} segments with buffers of [${preStart}, ${postEnd}]:`
  );
  console.log(segments);

  if (doTrim) {
    // bookmarks -> trims
    let trims = getPairs(bookmarks, [["startTrim", "endTrim"]]);
    if (trims) {
      // segments -> trimmed segments
      console.log(`${trims.length} trims to consider:`);
      console.log(trims);
      let untrimmedSegments = segments;
      segments = segmentize(
        segments,
        trims.map((x) => x[1])
      );
      console.log(
        `${segments.length} total segments ` +
          `(${
            segments.length - untrimmedSegments.length
          } new) after making trims:`
      );
      console.log(segments);
    }
  }
  return segments;
}

function getChopCommands(segments, inFile, outDir, muteVideo) {
  let ii = 1;
  return segments.map((x) =>
    getChopCommand(x, inFile, outDir, ii++, muteVideo)
  );
}

// https://trac.ffmpeg.org/wiki/Seeking
// ffmpeg -ss 00:00:02.3 -to 00:00:05.5 -i smaller2.mov -c copy output23.mp4
function getChopCommand(segment, inFile, outDir, index, muteVideo) {
  const fadeDuration = 1.0;
  let [start, end] = segment;

  let filter = `-vf "fade=out:st=${phpRound(
    end - start - fadeDuration
  )}:d=${fadeDuration}"`;
  filter = "-c copy";
  let outPath = `${outDir}/${index + 100}.mp4`;

  // todo: better escaping for path names with unexpected chars
  // -strict flag: https://stackoverflow.com/questions/44760588/preserving-side-data-information-for-360-video-transcoding-using-ffmpeg/48147865#48147865
  // -na for muting
  let cmd = `ffmpeg -y -ss ${phpRound(start)} -t ${phpRound(end - start)}${
    muteVideo ? " -an" : ""
  } -i "${inFile}" ${filter} -strict experimental "${outPath}"`;
  return cmd;
}

function getBookmarksWithContext(bookmarks) {
  let curPoint = null;
  let possession = null;
  return bookmarks.map((bookmark) => {
    let [timestamp, event] = bookmark;
    if (event === "pull") {
      curPoint = "pull";
      possession = 0;
    } else if (event === "receive") {
      curPoint = "receive";
      possession = 1;
    } else if (event === "turnover") {
      // TODO: reset possession after goal
      if (possession !== null) {
        possession = 1 - possession;
      }
    }
    let longName = event;
    if (longName === "turnover") {
      longName = possession === 0 ? "turnover (us)" : "turnover (them)";
    }
    return [timestamp, event, curPoint, possession, longName];
  });
}
function getEvents(bookmarks, eventFilter, possession) {
  let onlyPossession = {
    either: null,
    us: 1 /* offense */,
    them: 0 /* defense */,
  }[possession];
  if (onlyPossession !== null) {
    return getPossessions(bookmarks, onlyPossession);
  } else if (eventFilter[1].length === 0) {
    return getSingles(bookmarks, eventFilter[0]);
  } else {
    let allPairs = [];
    // do cross product of two arrays
    eventFilter[0].forEach((x) =>
      eventFilter[1].forEach((y) => allPairs.push([x, y]))
    );
    return getPairs(bookmarks, allPairs);
  }
}

function getPossessions(bookmarks, targetPossession) {
  let allSegments = [];
  let curStart = null;
  bookmarks.forEach((bookmark) => {
    let [timestamp, event, curPoint, possession, longName] = bookmark;
    if (curStart === null) {
      if (
        ["pull", "receive", "turnover"].includes(event) &&
        possession === targetPossession
      ) {
        curStart = [timestamp, event];
      }
      return;
    }
    if (curStart !== null) {
      if (
        event === "goal" ||
        (event === "turnover" && possession !== targetPossession)
      ) {
        allSegments.push([
          [curStart[1], event],
          [curStart[0], timestamp],
        ]);
        curStart = null;
      }
      return;
    }
  });
  return allSegments;
}

function bookmarkMatchesEventFilter(bookmark, eventFilter) {
  if (eventFilter.includes(bookmark[4])) {
    return true;
  }
  return false;
}

function getSingles(bookmarks, eventFilter) {
  return bookmarks.reduce((acc, bookmark) => {
    if (bookmarkMatchesEventFilter(bookmark, eventFilter)) {
      acc.push([bookmark[1], bookmark[0]]);
    }
    return acc;
  }, []);
}

// bookmarks - array of bookmarks.
// ex: [[1747,"receive"],[1824,"goal"],[1854,"receive"],[1908,"turnover"]]
// pairs of action
// ex: [[receive, goal], [receive, turnover]]
// returns matching pairs:
// ex:  [['receive', 'turnover'], [44, 47]] [ ['receive', 'goal'], [48, 50] ]]
function getPairs(bookmarks, pairs) {
  let segments = [];
  let previous = null;
  let startEvents = {};
  let endEvents = {};
  pairs.forEach(function (pair) {
    let [start, end] = pair;
    if (!startEvents[start]) {
      startEvents[start] = {};
    }
    if (!endEvents[end]) {
      endEvents[end] = {};
    }
    startEvents[start][end] = true;
    endEvents[end][start] = true;
  });
  bookmarks.forEach(function (bookmark) {
    let [timestamp, foo, bar, foobar, event] = bookmark;
    if (!startEvents[event] && !endEvents[event]) {
      // skip events we're not intrested in
      if (event === "goal") {
        previous = null;
      }
      return;
    }
    if (previous === null) {
      if (!startEvents[event]) {
        throw new Error("Seeing an end event without a start event: " + event);
      }
      previous = { event, timestamp };
    } else {
      if (!startEvents[previous.event] || !startEvents[previous.event][event]) {
        throw new Error(
          `${previous.event} (@${previous.timestamp}) cannot be followed by ${event} (@${timestamp}).`
        );
      }
      segments.push([
        [previous.event, event],
        [previous.timestamp, timestamp],
      ]);
      previous = null;
    }
  });
  return segments;
}

function getSegments(events, preStart, postEnd) {
  return events.map((event) => {
    let timeStart, timeEnd;
    let timestamp = event[1];
    if (!Array.isArray(timestamp)) {
      timeStart = timeEnd = timestamp;
    } else {
      [timeStart, timeEnd] = timestamp;
    }
    timeStart = Math.max(0, timeStart - preStart);
    timeEnd = timeEnd + postEnd;
    return [timeStart, timeEnd];
  });
}

function segmentize(untrimmed, trims) {
  untrimmed = untrimmed.slice(0);
  trims = trims.slice(0);
  let trimmed = [];
  while (untrimmed.length) {
    if (!trims.length) {
      trimmed.push(...untrimmed);
      break;
    }
    let [untrimmedStart, untrimmedEnd] = untrimmed[0];
    let [trimStart, trimEnd] = trims[0];
    if (trimStart > untrimmedEnd) {
      trimmed.push(untrimmed[0]);
      untrimmed.shift();
      continue;
    }
    if (trimEnd < untrimmedStart) {
      trims.shift();
      continue;
    }
    trimmed.push([untrimmedStart, trimStart]);
    untrimmed.shift();
    if (trimEnd < untrimmedEnd) {
      untrimmed.unshift([trimEnd, untrimmedEnd]);
    }
    trims.shift();
  }
  return trimmed;
}

function phpRound(number, precision = 3) {
  var factor = Math.pow(10, precision);
  var tempNumber = number * factor;
  var roundedTempNumber = Math.round(tempNumber);
  return roundedTempNumber / factor;
}

let Chopper = { getCommandsEverything };
export default Chopper;
